<template>
  <div class="flex-container scrollable">
    <page-title value="Import paie"/>
    <Card class="mb-5 mt-5">
      <template #title>
        <div class="flex flex-row gap-2">
          <h2>Importer vos données de paie {{ (dateCSV && enableImport) ? ' du mois de ' + getFormattedCsvDate : '' }} (format CSV)</h2>
          <Button class="mb-5 p-button-outlined" icon="pi pi-file" v-tooltip.top="'Télécharger le template'" @click="downloadTemplate"/>
          <Button v-if="dateCSV && enableImport " class="mb-5 p-button-outlined" icon="pi pi-replay" @click="resetCsvFields"/>
        </div>
      </template>
      <template #content>
        <FileUpload v-if="dateCSV && enableImport" ref="fileUpload1" invalidFileLimitMessage="Le nombre de fichiers est limité à {0}"
                    choose-label="Sélectionner" upload-label="Téléverser" cancel-label="Annuler"
                    custom-upload :file-limit="1" accept=".csv"
                    @uploader="uploadCSV" @select="deleteUploadedFile">
          <template #empty>
            <p>Ajoutez votre fichier CSV ici.</p>
          </template>
        </FileUpload>
        <div v-else class="flex justify-content-center align-items-center" style="gap: 0.5rem">
          <label class="mr-3">Date d'entrée</label>
          <Calendar v-model="dateCSV" :showIcon="true" view="month" date-format="mm/yy" @update:modelValue="importDateChange"/>
        </div>
      </template>
    </Card>
    <Card>
      <template #title>
        <h2>PDF <span class="capitalize">{{ datePDF ? datePDF.toLocaleDateString(undefined, { year: 'numeric', month: 'long' }) : '' }}</span></h2>
      </template>
      <template #content>
        <FileUpload v-if="datePDF" ref="fileUpload2" choose-label="Sélectionner" upload-label="Téléverser" cancel-label="Annuler" custom-upload @uploader="(file) => { uploadPDF(file) }" :multiple="true" accept="application/pdf">
          <template #empty>
            <p>Ajoutez vos fichiers PDF ici.</p>
          </template>
        </FileUpload>
        <div v-else class="flex justify-content-center align-items-center" style="gap: 0.5rem">
          <label class="mr-3">Date d'entrée</label>
          <Calendar :showIcon="true" v-model="datePDF" view="month" date-format="mm/yy"></Calendar>
        </div>
      </template>
    </Card>
    <Dialog v-model:visible="uploading" :style="{width: '500px'}" :modal="true" content-class="dialog-content"
            :draggable="false" :show-header="false">
      <div class="flex justify-content-between" v-for="key in Object.keys(filesUploading)" :key="key">
          <span>{{ key }}</span>
          <ProgressBar style="width: 50%" :value="filesUploading[key]"></ProgressBar>
      </div>
    </Dialog>
    <Dialog v-model:visible="csvImportCheckDone" :style="{width: '1000px'}" :modal="true" :draggable="false"
            content-class="dialog-content" :show-header="false" :close-on-escape="false" @hide="resetCsvFields">
      <h3 class="mb-0">Import des paies du mois de {{ getFormattedCsvDate }}</h3>
      <p v-if="csvImportError" class="mb-0 mt-0">Des erreurs ont été trouvées lors de l'import, corrigez le fichier d'import et réimporter le fichier.</p>
      <p v-if="!csvImportError" class="mt-0 mb-0">Selectionnez les paies à importer
        <i class="ml-1 pi pi-info-circle text-blue-500 cursor-pointer" @click="this.showInfo = !this.showInfo" />
      </p>
      <p v-if="showInfo" class="pay-info mt-0 text-sm">Les paies des portes non-séléctionnées correspondent aux portes ayant déjà reçu une paie pour la date choisie</p>

      <DataTable ref="dt" :value="parsedCsvData" v-model:filters="csvTableFilters" v-model:selection="selectedCsvResultData"
                 scrollable scroll-height="flex" :paginator="true" :rows="constants.defaultRowNumber" class="p-datatable-sm text-xs"
                 :rowsPerPageOptions="constants.rowsPerPageOptions" data-key="independentId" :rowHover="true"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.pays')})">
        <template #empty>
          {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
        </template>
        <template #loading>
          {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
        </template>
        <Column selectionMode="multiple" :hidden="csvImportError"/>
        <Column header="Statut" :hidden="!csvImportError">
          <template #body="{data}">
            <div>
              <Chip :label="data?.accepted ? 'VALIDE' : (data?.fromSociety ? 'MANQUANT' : 'INCONNU')"
                    :class="data?.accepted ? 'bg-primary' : `text-white ${data?.fromSociety ? 'bg-red-400' : 'bg-orange-400'}`"
                    class="text-sm w-full justify-content-center"/>
            </div>
          </template>
        </Column>
        <Column field="matricule" header="Matricule"/>
        <Column field="socialSecurityCode" header="Numéro de sécurité social"/>
        <Column field="name" header="Nom Prénom"/>
        <Column field="salaire_net" header="Salaire Net"/>
        <Column field="brut" header="Salaire Brut"/>
        <Column field="charges_patronales" header="Charges patronales"/>
        <Column field="charges_salariales" header="Charges salariales"/>
<!--        <Column field="forfaits" header="Forfait"/>-->
        <Column field="cout_global_entreprise" header="Cout global entreprise"/>
        <Column field="pas" header="PAS"/>
        <Column field="net_a_payer" header="Net a payer"/>
        <Column field="reserve" header="Reserve"/>
      </DataTable>
      <div class="flex flex-row-reverse">
        <Button v-if="!csvImportError" label="Importer" :disabled="selectedCsvResultData?.length === 0" @click="importDialogValidation = true" />
        <Button :class="!csvImportError ? 'p-button-outlined mr-2' : ''" :label="!csvImportError ? 'Annuler' : 'Fermer'" :disabled="importLoading" @click="resetCsvFields"/>
      </div>
    </Dialog>
    <Dialog v-model:visible="importDialogValidation" :closable=false @close="importDialogValidation = false"
            header="Etes vous sûr d'importer ?" :style="{width: '400px'}" :modal="true" @hide="closeValidationImportDialog">
      <div class="pt-3 pb-3 flex flex-row-reverse">
        <div class="flex col-8 justify-content-between">
          <Button class="p-button-outlined" label="Fermer" :disabled="importLoading" @click="closeValidationImportDialog"/>
          <Button label="Continuer" @click="importSelectedCsvLines" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import {csvToJson} from "@/utils/CsvLoader";
import Alert from "@/utils/Alert";
import {mapState} from "vuex";
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import PageTitle from "@/components/PageTitle.vue";
import {setDateWithoutHours} from "@/utils/Date";

export default {
  name: "Import",
  components: {PageTitle},
  data () {
    return {
      hasAccess: hasAccess,
      csvResultData: [],
      parsedCsvData: [],
      selectedCsvResultData: null,
      csvImportCheckDone: false,
      dateCSV: null,
      datePDF: null,
      uploading: false,
      filesUploading: {},
      filesUploaded: 0,
      csvTableFilters: {},
      showInfo: false,
      importLoading: false,
      importDialogValidation: false,
      csvImportError: false,
      enableImport: false
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    getFormattedCsvDate () {
      const monthFormatter = new Intl.DateTimeFormat('fr', { month: 'long' });
      const yearFormatter = new Intl.DateTimeFormat('fr', { year: 'numeric' });
      return `${monthFormatter.format(this.dateCSV)} ${yearFormatter.format(this.dateCSV)}`
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  methods: {
    async downloadTemplate () {
      await this.$store.dispatch('pay/importPayrollsTemplate')
    },
    async importSelectedCsvLines () {
      this.importLoading = true
      try {
        await this.$store.dispatch('pay/importIndependentPay', { independentPays: this.selectedCsvResultData, date: this.dateCSV })
        Alert.successMessage(this, 'importPays')
      } catch (error) {
        const key = `importPays${error?.response?.data?.response?.errors[0]?.extensions?.code ? `-${error?.response?.data?.response?.errors[0]?.extensions?.code}`
            : (error?.response?.data?.error ? `-${error?.response?.data?.error}` : '')}`
        Alert.errorMessage(this, key, {}, 8000)
      }
      this.importLoading = false
      this.importDialogValidation = false
      this.resetCsvFields()
    },
    resetCsvFields () {
      this.csvImportCheckDone = false
      this.enableImport = false
      this.csvImportError = false
      this.dateCSV = null
    },
    async importDateChange () {
      try {
        await this.$store.dispatch('pay/isImportPayEnabled', {
          date: setDateWithoutHours(this.dateCSV)
        })
        this.enableImport = true
      } catch (e) {
        this.$toast.add({ severity: 'error', detail: `Il reste des paie non exportés parmi les portés actifs`, life: 5000 });
        this.dateCSV = null
        this.enableImport = false
      }
    },
    deleteUploadedFile () {
      process.nextTick(() => {
        if (this.$refs.fileUpload1.files.length > 1) {
          this.$refs.fileUpload1.files.shift()
        }
      })
    },
    closeValidationImportDialog () {
      this.importDialogValidation = false
      this.resetCsvFields()
    },
    uploadCSV (csv) {
      const filename = csv.files[0].name.split('.')[0]
      this.uploading = true
      this.filesUploading[filename] = 0
      csvToJson(csv, async (jsonResult) => {
        try {
          this.csvResultData = jsonResult
          this.parsedCsvData = await this.$store.dispatch('pay/checkIndependentPay', {
            independentPays: jsonResult,
            date: setDateWithoutHours(this.dateCSV)
          })
          this.selectedCsvResultData = this.parsedCsvData.filter(line => line.accepted)
          this.uploading = false
          this.csvImportCheckDone = true
        } catch (error) {
          this.parsedCsvData = error.response.data.data
          this.csvImportError = true
          this.uploading = false
          this.csvImportCheckDone = true
        }
      })
    },
    uploadPDF (files) {
      // todo: should send a form multipart with the file and the document information
      this.uploading = true
      files.files.forEach(async (file) => {
        let filename = file.name.split('.')
        filename.pop()
        const personalNumber = filename[0].split('_')[0]

        // todo: uncomment if needed validation
        // if (!(this.constants.REGEX_VALIDATORS.SOCIAL_SECURITY_CODE.test(NSecu))) {
        //   Alert.errorMessage(this, 'invalidSocSecurityCode')
        //   this.uploading = false
        //   return
        // }

        const form = new FormData()
        form.append('filename', file.name)
        form.append('file', file, file.name)

        this.filesUploading[file.name] = 0

        const data = {
          'data': form,
          'progressFunction': (progress) => {
            let percentCompleted = Math.round((progress.loaded * 100) / progress.total)
            this.filesUploading[file.name] = percentCompleted
          }
        }

        const document = (await this.$store.dispatch('file/uploadFile', data)).id

        this.filesUploaded++

        if (this.filesUploaded === Object.keys(this.filesUploading).length) {
          this.filesUploading = {}
          this.uploading = false
          this.$refs.fileUpload2.files = []
        }

        const value = {
          linker: personalNumber,
          document: {
            linkerType: this.constants.LINKER_TYPE.PERSONAL_NUMBER,
            status: this.constants.DOCUMENT_STATUS.VALIDATED, // todo: might be draft document in the end ?
            type: this.constants.DOCUMENT_TYPE.PAY_SLIP,
            document: document,
            metadata: { date: setDateWithoutHours(this.datePDF) }
          }
        }
        await this.$store.dispatch('independent/createPayRollIndependentDocument', value)
      })
      this.filesUploaded = 0
    }
  }
}
</script>

<style>
.dialog-content {
  padding: 2rem !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
  background-color: white;
}
</style>
